<template>
  <div class="landing">
    <div class="container">
      <div class="landing__top top">
        <div class="top__title">Казино онлайн</div>
        <div class="top__subtitle">Лучшие бонусы в лицензионном казино</div>
        <div class="top__tags">
          <div
            v-for="{title, icon} in TOP_TAGS"
            :key="icon"
            class="top__tag"
          >
            <img
              class="top__tag-icon"
              :src="require(`./assets/img/icons/${icon}`)"
              alt=""
            >
            <div class="top__tag-title">{{ title }}</div>
          </div>
        </div>
      </div>
      <div class="landing__games games">
        <a
          v-for="game in GAMES"
          :key="game"
          href="#"
          class="games__item"
          @click.prevent="scrollTo('sites')"
        >
          <img
            class="games__item-img"
            :src="require(`./assets/img/games/${game}`)"
            alt=""
          >
        </a>
      </div>
      <div
        class="landing__sites sites"
        id="sites"
      >
        <div
          v-for="{name, logo, shakeAnimation, minDeposit, RTP, url, tags} in SITES"
          :key="name"
          class="sites__item site"
          :class="{'shake_animation': shakeAnimation}"
        >
          <div class="site__top">
            <img
              :src="require(`./assets/img/sites/${logo}`)"
              alt=""
              class="site__logo"
            >
            <div class="site__tags">
              <template
                v-for="{type, text, color} in tags"
                :key="text"
              >
                <div
                  v-if="type === 'single'"
                  class="tag"
                  :class="`tag_${color}`"
                >
                  <span>{{ text }}</span>
                </div>
                <div
                  v-else
                  class="tag-group"
                >
                  <div
                    v-for="txt in text"
                    :key="txt"
                    class="tag"
                    :class="`tag_${color}`"
                  >
                    <span>{{ txt }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="site__requirements">
            <div class="site__min-deposit">
              <div class="site__requirements-text">Депозит от</div>
              <div class="site__requirements-sum"> {{ minDeposit }} {{ CURRENCY }}</div>
            </div>
            <div class="site__RTP">
              <div class="site__requirements-text">RTP</div>
              <div class="site__requirements-sum"> {{ RTP }}%</div>
            </div>
          </div>
          <div class="site__jackpot">
            <div>Jackpot</div>
            <div class="site__jackpot-sum">
              <IncrementedJackpot />
              <span>&nbsp;{{ CURRENCY }}</span>
            </div>
          </div>
          <a
            :href="`${url}${codeUrlString}`"
            class="site__button"
          >
            <span>Перейти</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncrementedJackpot from '@/components/IncrementedJackpot.vue'
import { CURRENCY, GAMES, SITES, TOP_TAGS } from './constants'

export default {
  name: 'LandingPage',
  components: { IncrementedJackpot },
  data () {
    return {
      code: ''
    }
  },
  created () {
    this.TOP_TAGS = TOP_TAGS
    this.GAMES = GAMES
    this.SITES = SITES
    this.CURRENCY = CURRENCY

    this.setActivationCode()
    window.history.replaceState({}, '', '/')
  },
  computed: {
    codeUrlString () {
      return this.code ? `?activation_code=${this.code}&btag=${this.code}` : ''
    }
  },
  methods: {
    setActivationCode () {
      const urlParams = new URLSearchParams(window.location.search)

      if (!urlParams.has('code')) {
        return
      }
      this.code = urlParams.get('code')
    },
    scrollTo (blockID) {
      document.getElementById(blockID).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
