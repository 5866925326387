export const TOP_TAGS = [
  {
    title: 'Лучшие бонусы',
    icon: 'icon-cashback.svg'
  },
  {
    title: 'Джекпоты',
    icon: 'icon-diamond.svg'
  },
  {
    title: 'Саппорт 24/7',
    icon: 'icon-support-agent.svg'
  },
  {
    title: 'Быстрые выплаты',
    icon: 'icon-payout.svg'
  }
]

export const GAMES = [
  'sirens-riches.webp',
  'dragons-mirror.webp',
  'sunlight-princess.webp',
  'olympic-lucks.webp'
]

export const SITES = [
  {
    name: 'LEX',
    logo: 'lex.webp',
    shakeAnimation: true,
    minDeposit: 300,
    RTP: 96,
    url: 'https://lex-irrs01.com/c66b8de6c',
    tags: [
      {
        type: 'group',
        color: 'purple',
        text: [
          'Ставки до 100 000 RUB',
          'для хайроллеров'
        ]
      }
    ]
  },
  {
    name: '1GO',
    logo: '1go.webp',
    minDeposit: 300,
    RTP: 95,
    url: 'https://1go-irit.com/cce437ce5',
    tags: [
      {
        type: 'single',
        text: 'Еженедельный кэшбэк 10%',
        color: 'green'
      }
    ]
  },
  {
    name: 'MONRO',
    logo: 'monro.webp',
    minDeposit: 300,
    RTP: 95,
    url: 'https://mnr-irrs01.com/ce17e66e8',
    tags: [
      {
        type: 'single',
        text: 'Быстрая регистрация',
        color: 'red'
      },
      {
        type: 'single',
        text: 'Саппорт 24/7',
        color: 'orange'
      }
    ]
  },
  {
    name: 'DRIP',
    logo: 'drip.webp',
    minDeposit: 300,
    RTP: 95,
    url: 'https://drp-irrs12.com/c8661d342'
  },
  {
    name: 'STARDA',
    logo: 'starda.webp',
    shakeAnimation: true,
    minDeposit: 300,
    RTP: 95,
    url: 'https://strd-irrs12.com/c2eebac24',
    tags: [
      {
        type: 'single',
        text: 'Быстрая регистрация',
        color: 'red'
      },
      {
        type: 'single',
        text: 'Саппорт 24/7',
        color: 'orange'
      }
    ]
  },
  {
    name: 'LEGZO',
    logo: 'legzo.webp',
    minDeposit: 300,
    RTP: 95,
    url: 'https://gzo-irrs01.com/cc6a3b346',
    tags: [
      {
        type: 'group',
        color: 'blue',
        text: [
          'Быстрый вывод',
          'БЕЗ комиссии'
        ]
      }
    ]
  },
  {
    name: 'IZZI',
    logo: 'izzi.webp',
    minDeposit: 300,
    RTP: 95,
    url: 'https://izzi-irrs01.com/ce814db37',
    tags: [
      {
        type: 'group',
        color: 'blue',
        text: [
          'Быстрый вывод',
          'БЕЗ комиссии'
        ]
      }
    ]
  },
  {
    name: 'JET',
    logo: 'jet.webp',
    minDeposit: 300,
    RTP: 95,
    url: 'https://jtfr-itsokarsn.com/c7f0a8ea6',
    tags: [
      {
        type: 'single',
        text: 'Быстрая регистрация',
        color: 'red'
      },
      {
        type: 'single',
        text: 'Саппорт 24/7',
        color: 'orange'
      }
    ]
  },
  {
    name: 'FRESH',
    logo: 'fresh.webp',
    minDeposit: 300,
    RTP: 95,
    url: 'https://fresh-sfgjhyrt02.com/cad086843',
    tags: [
      {
        type: 'single',
        text: 'Саппорт 24/7',
        color: 'orange'
      }
    ]
  },
  {
    name: 'SOL',
    logo: 'sol.webp',
    minDeposit: 300,
    RTP: 95,
    url: 'https://sol-diamew02.com/c65f6c611',
    tags: [
      {
        type: 'single',
        text: 'Быстрая регистрация',
        color: 'red'
      },
      {
        type: 'single',
        text: 'Саппорт 24/7',
        color: 'orange'
      }
    ]
  },
  {
    name: 'ROX',
    logo: 'rox.webp',
    minDeposit: 300,
    RTP: 95,
    url: 'https://rox-fwuocypyjf.com/c84b49ab2'
  },
  {
    name: 'VOLNA',
    logo: 'volna.webp',
    minDeposit: 300,
    RTP: 95,
    url: 'https://vln-irrs01.com/c42e4c4ea'
  }
]

export const CURRENCY = 'RUB'
