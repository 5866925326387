<template>
  <div class="jackpot">{{ jackpotString }}</div>
</template>

<script>
export default {
  name: 'IncrementedJackpot',
  props: {
    start: {
      type: Number,
      default: 1100000000
    },
    startMaxDelta: {
      type: Number,
      default: 50000000
    },
    deltaMinIncrement: {
      type: Number,
      default: 150
    },
    deltaMaxIncrement: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      currentJackpot: 0
    }
  },
  created () {
    const min = this.start - this.startMaxDelta
    const max = this.start + this.startMaxDelta
    this.currentJackpot = this.getRandom(min, max)

    setInterval(() => {
      this.currentJackpot += this.getRandom(this.deltaMinIncrement, this.deltaMaxIncrement)
    }, 2000)
  },
  computed: {
    jackpotString () {
      return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(this.currentJackpot)
    }
  },
  methods: {
    getRandom (min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  }
}
</script>
